import React, { Component } from 'react'
import { Col, Form, FormGroup, Label, Input, FormText, Spinner, Button } from 'reactstrap';
import MiniSidebar from '../../../../../global/MiniSidebar';
import { openGlobalSnackbar } from '../../../../../actions/snackBarAction';
import { changePass } from '../../../../../actions/profileActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormValidationError from '../../../../../global/FormValidationError';

class ChangePassword extends Component {
    state = {
        currentPassword: '',
        newPassword: '',
        confNewPassword: '',
        inputType: true,
        passwordErr: 'At least 8 characters, but longer is better',
        oldPasswordErrText: 'this field is required',
        confError: 'Password must match with New Password',
        disabled: true,
        load: false,
        oldPasswordErr: false,
        newPasswordErr: false,
        confNewPasswordErr: false,
    }

    componentDidMount() {
        document.title = "Peymynt - Change Password";
    }

    _handleText(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if (!value) {
            this.setState({
                [`${name}Err`]: true,
            })
        } else {
            this.setState({
                [`${name}Err`]: false
            })
            if (name === 'newPassword') {
                this.setState({ passwordErr: 'At least 8 characters, but longer is better' })
            }
            if (name === 'confirmPassword') {
                this.setState({ confError: 'Password must match with New Password' })
            }
        }
    }

    _showPass(e) {
        this.setState({ inputType: !this.state.inputType })
    }

    _handleForm = async (e) => {
        e.preventDefault();
        const { newPassword, confNewPassword, oldPassword } = this.state;
        if (newPassword.length >= 8 && oldPassword.length >= 6) {
            this.setState({ passwordErr: '', newPasswordErr: false })
            if (newPassword === confNewPassword) {
                this.setState({ disabled: false, confError: "", confNewPasswordErr: false, load: true })
                let response = await this.props.changePass({ newPassword, currentPassword: oldPassword })
                if (response.statusCode === 200) {
                    this.setState({ load: false })
                    this.props.openGlobalSnackbar("Password changed successfully", false);
                } else {
                    this.setState({ load: false })
                    this.props.openGlobalSnackbar(response.message, true);
                }
            } else {
                this.setState({ disabled: true, confNewPasswordErr: true, confError: "password should match with new password", load: false })
            }
        } else {
            if (!!oldPassword) {
                if (oldPassword.length < 6) {
                    this.setState({ oldPasswordErr: true, oldPasswordErrText: 'should be 6 characters long' })
                } else {
                    this.setState({ oldPasswordErr: false, oldPasswordErrText: '' })
                }

            } else {
                this.setState({ oldPasswordErr: true })
            }
            if (!!newPassword) {
                if (newPassword.length < 8) {
                    this.setState({ passwordErr: "Uh oh, this password isn't strong enough.", disabled: true, newPasswordErr: true })
                }
                if (newPassword === confNewPassword) {
                    this.setState({ disabled: false, confError: "", confNewPasswordErr: false })
                } else {
                    this.setState({ disabled: true, confNewPasswordErr: true, confError: "password should match with new password" })
                }
            } else {
                this.setState({ passwordErr: "this field is required", disabled: true, newPasswordErr: true })
            }
        }
    }

    render() {
        const { params } = this.props;
        const { oldPassword, newPassword, confNewPassword, inputType, newPasswordErr, passwordErr, confNewPasswordErr, confError, disabled, load } = this.state;
        let lists = [
            { name: 'Personal Information', link: `/app/${params.userId}/accounts` },
            { name: 'Emails & Connected Accounts', link: `/app/${params.userId}/accounts/email-connected` },
            { name: 'Password', link: `/app/${params.userId}/accounts/password`, className: "active" },
            { name: 'Email Notifications', link: `/app/${params.userId}/accounts/email-notification` },
            { name: 'Businesses', link: `/app/${params.userId}/accounts/business` }
        ]
        return (
            <div className="py-frame__page py-frame__settings has-sidebar">
                <MiniSidebar heading={'Profile'} listArray={lists} />

                <div className="py-page__content">
                    <div className="py-page__inner">
                        <div className="py-header--page flex">
                            <div className="py-header--title">
                                <h2 className="py-heading--title">Change Your Password</h2>
                            </div>
                        </div>
                        <Form className="py-form-field--condensed" onSubmit={this._handleForm.bind(this)}>
                            <div className="row mx-n2 mb-2" >
                                <div className="col-12 col-md-4 text-md-right px-2" >
                                    <Label htmlFor="oldPassword" className="is-required no-abs mt-3">Old Password</Label>
                                </div>
                                <div className="col-12 col-md-8 px-2" >
                                    <Input type="password"
                                        value={oldPassword}
                                        type="password"
                                        name="oldPassword"
                                        id="oldPassword"
                                        placeholder="Password"
                                        onChange={this._handleText.bind(this)} />
                                    <FormValidationError
                                        message={this.state.oldPasswordErrText}
                                        showError={this.state.oldPasswordErr}
                                    />
                                </div>
                            </div>
                            <div className="row mx-n2 mb-2" >
                                <div className="col-12 col-md-4 text-md-right px-2" >
                                    <Label htmlFor="newPass" className="is-required no-abs mt-3">New Password</Label>
                                </div>
                                <div className="col-12 col-md-8 px-2" >
                                    <Input type={inputType ? 'password' : "text"}
                                        value={newPassword}
                                        name="newPassword"
                                        id="newPassword"
                                        placeholder="Password"
                                        onChange={this._handleText.bind(this)} id="newPass" />
                                    <div className={"d-flex justify-content-between w-100"}>
                                        <FormValidationError
                                            showError={true}
                                            err={this.state.newPasswordErr}
                                            message={passwordErr}
                                        />
                                        <a className="text-right py-text--link" onClick={this._showPass.bind(this)}>{inputType ? 'Show' : 'Hide'}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-n2 mb-2" >
                                <div className="col-12 col-md-4 text-md-right px-2" >
                                    <Label htmlFor="confNewPassword" className="is-required no-abs mt-3">Confirm New Password</Label>
                                </div>
                                <div className="col-12 col-md-8 px-2" >
                                    <Input type="password"
                                        value={confNewPassword}
                                        name="confNewPassword"
                                        id="confNewPassword"
                                        placeholder="Password"
                                        type="password"
                                        onChange={this._handleText.bind(this)} />
                                    <div className={"d-flex justify-content-between w-100"}>
                                        <FormValidationError
                                            showError={true}
                                            err={this.state.confNewPasswordErr}
                                            message={confError}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-n2 mb-2" >
                                <div className="col-12 col-md-4 text-right px-2" ></div>
                                <div className="col-8 col-sm-4 px-2" >
                                    <Button color="primary" className="mn-w-100" disabled={load}>{load ? <Spinner color="default" size="sm" /> : 'Save'}</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    changePassword: state.changePass
})

export default withRouter((connect(mapStateToProps, { changePass, openGlobalSnackbar })(ChangePassword)))