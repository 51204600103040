import requestWithToken from './requestWithToken'

export const getMccWithBusinessCategory=()=> {
    return requestWithToken({
        url: `/api/v1/utility/mcc`,
        method: 'GET'
    });
}


