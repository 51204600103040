import axios from 'axios';
import React, { Component, Fragment } from 'react';
import CenterSpinner from '../../../../../global/CenterSpinner';

class ShowPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      renderInvoiceTemplate: ''
    }
  }

  componentDidMount() {
    let {
      userSettings,
      invoiceInput,
      businessInfo,
      selectedCustomer
    } = this.props;
      const sumofTax = invoiceInput.amountBreakup.taxTotal.length > 0 ? invoiceInput.amountBreakup.taxTotal.reduce((a,b) => {
        return a + b.amount
      },0) : 0
      const processingTipAmount =  invoiceInput.amountBreakup.tip || 0
      const totalWithTax =  sumofTax + invoiceInput.amountBreakup.subTotal + processingTipAmount || 0
       invoiceInput.amountBreakup["totalWithTax"] = totalWithTax;
    const data = {
      invoice: {
        ...invoiceInput,
        businessId: businessInfo,
        userId: userSettings.userId,
        uuid: userSettings.uuid,
        _id: userSettings._id,
        customer: selectedCustomer ? selectedCustomer : { customerName: "You've not added a customer." }
      },
      salesSetting: {
        ...userSettings,
        colours: {
          shadeColour: "#cccccc",
          textColour: "#1c252c",
          titleColour: "#1c252c99"
        }
      }
    }

    this.fetchTemplateHtml(data)
  }

  fetchTemplateHtml = (data) => {
    this.setState({ loading: true })
    axios
      .post(
        `${process.env.REACT_APP_API_GATEWAY_URL}/template-service/invoice.${data.salesSetting.template}/html`,
        { ...data }
      )
      .then((res) => {
        this.setState({ renderInvoiceTemplate: res.data }, () => this.handleResize())
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  onLoad = () => {
    this.iframeRef.contentWindow.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    if (this.iframeRef && this.iframeRef.contentWindow && this.iframeRef.contentWindow.document) {
      const { body, documentElement } = this.iframeRef.contentWindow.document;
      const iframeHeight = Math.max(
        body.clientHeight,
        body.offsetHeight,
        body.scrollHeight,
        documentElement.clientHeight,
        documentElement.offsetHeight,
        documentElement.scrollHeight
      );
      if (iframeHeight !== this.state.iframeHeight) this.setState({ iframeHeight });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        <div className="alert-action alert-info mt-4 mb-4">
          <div className="alert-icon">
            <svg className="Icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18zm0-2a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-11a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 3a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1z"></path></svg>
          </div>
          <div className="alert-content">
            <div className="alert-desc" >This is a preview of your invoice. Switch back to Edit if you need to make changes.</div>
          </div>
        </div>
        {loading ? <CenterSpinner /> :
          <iframe
            className="templateIframe"
            onLoad={this.onLoad}
            ref={(e) => { this.iframeRef = e }}
            style={{
              width: `820px`,
              height: `${this.state.iframeHeight}px`
            }}
            srcdoc={this.state.renderInvoiceTemplate}
            frameborder="0"
            scrolling="no"
          />
        }
      </Fragment>
    )
  }
}

export default ShowPreview;