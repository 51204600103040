import MobileHeader from "../../global/MobileHeader";
import Sidebar from "../../global/Sidebar";
import React from "react";
import BannerService from "../../api/bannerService";
import SnakeBar from "../../global/SnakeBar";
import DisplayBanner from "../common/DisplayBanner";
import { get as _get } from 'lodash';
import SettingsSidebar from "./components/setting/components/Sidebar";

class Layout extends React.Component {
  state = {
    open: false,
    addClass: false,
    layoutClass: "",
    bannerList: []
  };

  componentDidUpdate = (prevProps) => {
    const { BannerList } = this.props.reducer;
    if(BannerList !== prevProps.reducer.BannerList){
      this.getBannerList(BannerList && BannerList.data && BannerList.data.banner || [])
    }
  }

  getBannerList = (bannerList) => {
    if(bannerList) {
      const dismissedBanner = JSON.parse(localStorage.getItem("dismissedBanner"))
      if(dismissedBanner){
        let filterBanner = []; 
        dismissedBanner.map(val => {
          const data = bannerList.filter(item => val.bannerId !== item.bannerName)
          filterBanner = data
        })
        this.setState({
          bannerList: filterBanner || []
        })
      } else {
        this.setState({
          bannerList
        })
      }
    } else {
      this.setState({
        bannerList: []
      })
    }
  }

  handleDrawerOpen = () => {
    this.setState(prevState => ({
      addClass: !prevState.addClass
    }));
  };

  handleDrawerClose = () => {
    this.setState({ open: false, addClass: false });
  };

  handleRemoveBanner =async (id) => {
    const data = {
        status: "dismissed"
    }
    const response = await BannerService.updateBannerStatus(id, data)
    if(response && response.statusCode === 200) {
      const bannerData = await BannerService.fetchAllBanners()
      if(bannerData && bannerData.statusCode === 200 && bannerData.data){
        this.getBannerList(bannerData.data.banner || [])
      }
    }
  }

  render() {
    const { children, reducer} = this.props;
    const { BannerList } = reducer;
    const {addClass, bannerList } = this.state;

    let boxClass = ["main-app-container"];

    if (this.state.addClass) {
      boxClass.push("side-nav-collapsed");
    }

    let mainClass = ["bg-6", "main-content"];

    /*if (addClass) {
      mainClass.push("mrL250");
    }*/

    const currentPath = window.location.pathname;
    const showMenu = currentPath.includes("public") ? false : true;
    const showSettingsSidebar = currentPath.includes("setting");
    if (showSettingsSidebar) {
      // mainClass.push('has-settings-sidebar')
    }

    return (
      <div className={boxClass.join(" ")}>
        {/* <Header  handleDrawerOpen={this.handleDrawerOpen} /> */}
        <div className="app-content-wrapper main-body">
          {showMenu ? <Sidebar {...this.props} /> : null}
          <main className={mainClass.join(" ")}>
            <MobileHeader
              handleDrawerClose={this.handleDrawerClose}
              handleDrawerOpen={this.handleDrawerOpen}
              isOpen={addClass}
            />
            <SnakeBar />
            <div
              className={
                showSettingsSidebar
                  ? `py-frame__page py-frame__settings has-sidebar`
                  : "py-frame__page"
              }
            >
              {showSettingsSidebar ? <SettingsSidebar location={children.props} /> : null}
              <div className={`w-100 ${showSettingsSidebar ? "d-flex flex-column" : ""}`}>
              {!BannerList.loading ? bannerList.length ? bannerList.map(val => 
                  <DisplayBanner isSticky={val.isSticky} data={val} handleRemoveBanner={this.handleRemoveBanner}/>
                ) : ""
              : "" }
              {children}
              </div>
            </div>
          </main>
          {/* <Footer /> */}
        </div>
        {/* <Customizer /> */}
      </div>
    );
  }
}


export default Layout;
