import MainRoute from '../../../../components/app/MainRoute'
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import InvoiceCustomization from './components/InvoiceCustomization'
import Receipts from './components/Receipts'
import Payments from './components/Payments';
import UserManagement from './components/UserManagement'
import Payouts from './components/Payouts'
import SubscriptionPlans from './components/subscriptionPlans'
import SubscriptionUpdate from './components/subscriptionUpdate'
import Subscription from './components/subscription'
import UpdateCard from './components/updateCard'

export function SettingRoutes(url) {
  return (
    <Switch>
      <Redirect from="/app/setting" exact to="/app/setting/user-management" />
      <MainRoute exact path={`${url}/receipts`} component={Receipts} />
      <MainRoute exact path={`${url}/payouts`} component={Payouts} />
      <MainRoute
        exact
        path={`${url}/user-management`}
        component={UserManagement}
      />
      <MainRoute
        exact
        path={`${url}/invoice-customization`}
        component={InvoiceCustomization}
      />
      <MainRoute 
        exact 
        path={`${url}/payments`} 
        component={Payments} 
      />
      <MainRoute
        exact
        path={`${url}/subscription-plans`}
        component={SubscriptionPlans}
      />
      <MainRoute
        exact
        path={`${url}/subscription-update/:planId`}
        component={SubscriptionUpdate}
      />
      <MainRoute
        exact
        path={`${url}/subscription-history`}
        component={Subscription}
      />
      <MainRoute
        exact
        path={`${url}/update-card/:subscriptionId`}
        component={UpdateCard}
      />
    </Switch>
  )
}
