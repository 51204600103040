import React from 'react';
import { Link } from 'react-router-dom';
import { _getDiffDate } from '../../../../../../../utils/globalMomentDateFunc'
function Index({plan}) {
 return (
  <div className="current-plan-box">
      <div className="plan-box-header" >
        <div className="card-badge pull-right"><img src={`${process.env.REACT_APP_CDN_URL}/static/web-assets/subscription/current-plan-badge.png`} alt="Plan Badge" /></div>
        <div className="title">Your Current Plan</div>
        {plan ? <React.Fragment>
            <div className="price">${plan.amount.toLocaleString()}<small>/month</small></div>
            <div className="plan-name">{plan.planId.title}</div>
        </React.Fragment>
        :
        <React.Fragment>
            <div className="price">0<small>.00/month</small></div>
            <div className="plan-name">Starter</div>
        </React.Fragment>
        }
      </div>
        {/* <div className="plan-box-footer mt-auto pt-2" >
            {plan ? <React.Fragment>
                <Link className="bttn-white" to="/app/setting/subscription-plans" >Modify plan</Link>
                {plan.trial.isTrial && <div className="footer-info pull-right"><strong>Trial</strong> ends {_getDiffDate(plan.trial.endDate) == 0 ? 'today' : `in ${_getDiffDate(plan.trial.endDate)} day(s)`} </div>}
                {plan.endDate && !plan.trial.isTrial &&<div className="footer-info pull-right"><strong>Plan</strong> ends {_getDiffDate(plan.endDate) == 0 ? 'today' : `in ${_getDiffDate(plan.endDate)} day(s)`} </div>}
            </React.Fragment>
            :
                <React.Fragment>
                <Link className="bttn-white" to="/app/setting/subscription-plans" >Modify plan</Link>
                </React.Fragment>
            }
        </div> */}
  </div>
 );
}

export default Index;