import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import SubscriptionPlanCard from '../../../../../../global/subscriptionPlanCard';
import { getPlans } from '../../../../../../api/plansService';
import { openGlobalSnackbar } from '../../../../../../actions/snackBarAction';
import CenterSpinner from '../../../../../../global/CenterSpinner';
import { connect } from 'react-redux';
import history from '../../../../../../customHistory';
import { getActivePlan } from '../../../../../../api/subscriptionService';
import { _displayDate } from '../../../../../../utils/globalMomentDateFunc';

const Index = (props) => {
    const [plans, setPlans] = useState(null)
    const [activePlan, setActivePlan] = useState(null)
    const [activeSubscription, setActiveSubscription] = useState(null)
    const [upcomingDate, setUpcomingDate] = useState(null)

    useEffect(() => {
        initialFun()
    }, [])

    const initialFun = async () => {
        const activePlan = await getActivePlan()
        if (activePlan.data.upcoming) {
            setUpcomingDate(activePlan.data.upcoming.upcomingActivationDate)
            setActiveSubscription(activePlan.data.upcoming.planId._id)
            setActivePlan(activePlan.data.upcoming.planId._id)
        } else if (activePlan.data.current) {
            setUpcomingDate(null)
            setActiveSubscription(activePlan.data.current.planId._id)
            setActivePlan(activePlan.data.current.planId._id)
        }
        const plansResponse = await getPlans()
        setPlans(plansResponse.data)
    }

    const updatePlan = () => {
        if (activePlan) {
            if (activeSubscription == activePlan) {
                props.showSnackbar(`You are already subscribed to this plan ${upcomingDate ? `and it'll start on ${_displayDate(upcomingDate, 'MMMM DD YYYY')}` :''}`)
            } else {
                history.push(`/app/setting/subscription-update/${activePlan}`)
            }
        } else {
            props.showSnackbar("Select a plan to continue.")
        }
    }

    return (
        <div className="py-page__content pricing-plan-area w-100" >
            {!plans ?
                <div className="m-auto">
                    <CenterSpinner />
                </div>
                :
                <div className="content-wrapper__main__fixed" >
                    <div className="row">
                        <div class="py-header--title mt-0 mb-4 pb-2 col-8">
                            <h2 class="py-heading--title">Modify Plan</h2>
                        </div>
                    </div>
                    <div className="price-items row mx-n2" >
                        {plans && plans.map((plan) => (<div className="col-4 cursor-pointer px-2 mb-5" key={plan._id} onClick={() => setActivePlan(plan._id)}>
                            <SubscriptionPlanCard
                                isActive={activePlan === plan._id}
                                plan={plan}
                            />
                        </div>))}
                    </div>
                    {/* <div className="row align-items-center pt-1" >
                        <div className="col-xs-12" >
                            <Button onClick={updatePlan} color="primary" block ><span className="px-5">Modify</span></Button>
                        </div>
                    </div> */}
                </div>}
        </div>
    );
}


const mapPropsToState = ({ businessReducer }) => ({
    businessInfo: businessReducer.selectedBusiness,
});
const mapDispatchToProps = dispatch => {
    return {
        showSnackbar: (message, error) => {
            dispatch(openGlobalSnackbar(message, error))
        }
    }
}

export default connect(mapPropsToState, mapDispatchToProps)(Index)