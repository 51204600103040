import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CenterSpinner from '../../../../../../global/CenterSpinner';
import { handleAclPermissions } from "../../../../../../utils/GlobalFunctions";
class Sidebar extends Component {
  state = {
    location: 'app/setting/invoice-customization'
  }
  componentDidMount() {
    this.setState({
      location: window.location.pathname
    })
  }

  componentDidUpdate(prevProps) {
    if (this.state.location !== window.location.pathname) {
      this.setState({
        location: window.location.pathname
      })
    }
  }


  render() {
    const { paymentSettings: { loading, data }, location } = this.props;
    const isPermission = !handleAclPermissions(['Viewer', 'Editor'])
    return (
      <aside className="py-page__sidebar">
        {
          !!loading ? <CenterSpinner /> : (
            <div className="py-nav__sidebar">
              <h5 className="py-nav__heading">Settings</h5>
              <ul className="py-nav__section">
                <li className="title">User Management</li>
                <li>
                  <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/user-management') && 'is-active'} to='/app/setting/user-management'>Users</NavLink>
                </li>
                <li className="title">Sales</li>
                <li>
                  <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/invoice-customization') && 'is-active'} to='/app/setting/invoice-customization'>Customization</NavLink>
                </li>
                {
                  !!data && !!data.isVerified && !!data.isOnboardingApplicable && isPermission && (
                    <li>
                      <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/payments') && 'is-active'} to='/app/setting/payments'>Payments</NavLink>
                    </li>
                  )
                }
                {
                  !loading && data && data.isOnboardingApplicable && isPermission && (
                    <li>
                      <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/payouts') && 'is-active'} to='/app/setting/payouts'>Payouts</NavLink>
                    </li>
                  )
                }
              </ul>
              {/* <ul className="py-nav__section">
                <li className="title">Purchases</li>
                <li>
                  <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/receipts') && 'is-active'} to='/app/setting/receipts'>Receipts</NavLink>
                </li>
              </ul> */}
              {/*
                !loading && data && data.isOnboardingApplicable && (
                  <ul className="py-nav__section">
                    <li className="title">Banking</li>
                    <li>
                      <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/payouts') && 'is-active'} to='/app/setting/payouts'>Payouts</NavLink>
                    </li>
                  </ul>
                )
                */}
              {isPermission && <ul className="py-nav__section">
                <li className="title">Subscription</li>
                <li>
                  {!this.state.location.includes('setting/update-card') && <NavLink className="nav-link" activeClassName={this.state.location.includes('setting/subscription-history') && 'is-active'} to='/app/setting/subscription-history'>Details</NavLink>}
                  {this.state.location.includes('setting/update-card') && <NavLink className="nav-link is-active" activeClassName={'is-active'} to='/app/setting/subscription-history'>Details</NavLink>}
                </li>
              </ul>}
            </div>
          )
        }
      </aside>
    )
  }
}

const states = ({ paymentSettings }) => {
  return { paymentSettings }
}
export default connect(states, null)(Sidebar)