
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from './Layout';
import { requireAuth } from '../../index';
import { connect } from 'react-redux';
import history from '../../customHistory'
import RestrictedRoutes from '../../constants/planRestrictedAccount.json'

const MainRoute = ({ 'component': Component, params, ...rest }) => {

  // Route Restriction based on Subscription Plan
  const planRouteRestricted = () => {
    const { selectedBusiness } = rest.state.businessReducer
    // Check Businesses if they are created before subscription plans implementation
    if (selectedBusiness && (!selectedBusiness.subscription || !selectedBusiness.subscription.isSubscribed)){
      const getRestrictedRoutes = RestrictedRoutes['Starter'].restrictedRoutes
      if (getRestrictedRoutes.includes(rest.url)){
        redirectToUpgrade()
      }
      return !getRestrictedRoutes.includes(rest.url)
    } else{
      return true
    }
  }

  const redirectToUpgrade=()=>{
    history.push('/app/subscription/upgrade')
  }

  return (
    <Route {...rest} render={
       // Check if logged In
      requireAuth(rest.state, rest.path) ?
        // Check if routes module allowed in Subscription plan
        planRouteRestricted() ?
          matchProps => (
            <Layout reducer={rest.state}>
              <Component {...matchProps} params={params} url={rest.url} />
            </Layout>
          )
          : ''
        : ''
    } />
  );
};

const mapStateToProps = state => {
  return {
    'state': state
  };
};

export default connect(mapStateToProps, null)(MainRoute);
