export const GET_TRANSACTIONS_DATA_LOADING = 'GET_TRANSACTIONS_DATA_LOADING'
export const GET_TRANSACTIONS_DATA_SUCCESS = 'GET_TRANSACTIONS_DATA_SUCCESS'
export const GET_TRANSACTIONS_DATA_ERROR = 'GET_TRANSACTIONS_DATA_ERROR'

export const SAVE_TRANSACTIONS_LOADING = 'SAVE_TRANSACTIONS_LOADING'
export const SAVE_TRANSACTIONS_SUCCESS = 'SAVE_TRANSACTIONS_SUCCESS'
export const SAVE_TRANSACTIONS_ERROR = 'SAVE_TRANSACTIONS_ERROR'

export const DISABLE_TRANSACTIONS_LOADING = 'DISABLE_TRANSACTIONS_LOADING'
export const DISABLE_TRANSACTIONS_SUCCESS = 'DISABLE_TRANSACTIONS_SUCCESS'
export const DISABLE_TRANSACTIONS_ERROR = 'DISABLE_TRANSACTIONS_ERROR'