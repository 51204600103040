import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import InputMask from 'react-input-mask'
import PhoneInput from 'react-phone-input-2'
import moment from "moment"
import 'react-phone-input-2/lib/style.css'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import CardStatement from '../../../payments/components/onBoarding/stepForm/stepFive/cardStatement'

export const Numbermask = props => {
  return (
    <div className="dateSelect">
      <InputMask
        mask={props.schema.mask}
        value={props.value}
        placeholder={props.schema.placeholder}
        className="form-control"
        onChange={e => {
          props.onChange(e.target.value)
        }}
      />
    </div>
  )
}

export const BusinessTypeInputMask = props => {
  const [label, setLabel] = useState(props.schema.enum)
  return (
    <div id="onboarding_stepone">
      {label.map((ele, i) => {
        return (
            <div
                key={i}
                className="payment__onboard__business__type__list"
                style={{ textAlign: 'left' }}
            >
                <div
                    className={`${
                        props.value == ele ? 'selectedOptions' : 'selectOptions'
                    }`}
                    onClick={() => props.schema.disabled ? '': props.onChange(ele)}
                >
                    {props.schema.enumNames[i]}
                </div>
            </div>
        )
      })}
    </div>
  )
}

export const BusinessPhoneMask = props => {
  return (
    <PhoneInput
      disableSearchIcon
      countryCodeEditable={false}
      value={props.value ? props.value : ''}
      country={props.schema.countrycode}
      enableSearch
      onChange={e => {
        props.onChange(e)
      }}
      inputClass="w-100"
    />
  )
}

export const DobMask = props => {
  const [startDate, setStartDate] = useState()
  return (
    <div className="dateSelect">
      <DatePicker
        className="form-control"
        value={props.value}
        onChange={(date, e) => {
          let dateString = moment(date).format("MM/DD/YYYY")
            setStartDate(date)
            dateString = dateString.toString()
          props.onChange(dateString)
        }}
        placeholderText="MM/DD/YYYY"
        dateFormat={'MM/dd/yyyy'}
        maxDate={new Date()}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onKeyDown={e => {
          e.preventDefault()
        }}
      />
    </div>
  )
}

export const DisplayMask = props => {
  const [displayname, setDisplayname] = useState(props.formContext.legalName)
  const [count,setCount] = useState(0)
  useEffect(() => {
    if(props.formContext.legalName !== ""){
      props.onChange(props.formContext.legalName)
    }
  },[count])
  useEffect(() =>{
    if(props.formContext.legalName === ""){
        props.onChange(props.value)
        setDisplayname(props.value)
      }
      if(props  && count < 3){
        setCount(count+1)
      }
  },[props])
  return (
    <div>
      <Input
        type="text"
        name={props.label}
        value={displayname}
        onChange={e => {
            props.onChange(e.target.value);
            setDisplayname(e.target.value);
        }}
      />
      <div style={{ marginLeft: '90px' }}>
        <CardStatement
          displayName={
            displayname ? displayname.toUpperCase().slice(0, 19) : ''
          }
        />
      </div>
    </div>
  )
}

export const Numberformatmask = props => {
  const [val, setVal] = useState()
  return (
    <div className="dateSelect">
      <NumberFormat
        thousandSeparator={true}
        value={props.value}
        className="form-control"
        onValueChange={values => {
          props.onChange(values.floatValue)
        }}
      />
    </div>
  )
}


export const TermsAndConditionMask = props => {
    return (
      <div>
        <label for="privacy-policy" className="py-checkbox">
          <input
            type="checkbox"
            id="privacy-policy"
            value={props.value}
            required={props.required}
            defaultValue='true'
            onChange={event => {
              props.onChange(event.target.checked)
            }}
            checked={props.value}
          />
          <span className="py-form__element__faux"></span>
          <div  style={{marginLeft:"10px"}} dangerouslySetInnerHTML={{ __html: props.schema.title }} />
        </label>
      </div>
    )
  }