
import request from './request';
import { HTTP_GET } from '../components/app/components/Estimates/components/constant';
import requestWithToken from './requestWithToken';

function authenticate(data) {
  return request({
    url: '/api/v2/auth/web',
    method: 'POST',
    data,
  });
}


// function googleAuth(data) {
//   return request({
//     url: '/api/v1/authenticate/google',
//     method: 'POST',
//     data,
//   });
// }

function googleAuth(data) {
  return request({
    url: `api/v2/auth/login/google/callback?code=${data.code}`,
    method: 'GET',
    data:{},
  });
}

export const forgotPassword = (data) => request({
  url: '/api/v1/utility/forgot',
  method: 'POST',
  data,
});

const generateResetLink = (data) => request({
  url: '/api/v1/authenticate/password/reset',
  method: 'POST',
  data,
});

const resetPassword = (data) => request({
  url: '/api/v1/authenticate/password/reset/confirm',
  method: 'POST',
  data,
});

const verifyResetLink = (token) => request({
  url: `/api/v1/authenticate/password/reset/${token}/verify`,
  method: 'GET',
});

const assumeUser = (token) => requestWithToken({
  url: `/api/v2/auth/token/refresh`,
  method: 'POST',
  authToken: token.refreshToken,
  data:token
});

const refreshToken = (data) => requestWithToken({
  url: '/api/v2/auth/token/refresh',
  method: 'POST',
  data,
});
const callMe = (_) => requestWithToken({
  url: '/api/v2/auth/me',
  method: HTTP_GET,
});

const inviteUser = (data) => requestWithToken({
  url: '/api/v1/delegate',
  method: 'POST',
  data,
});

const updateUser = (data, id) => requestWithToken({
  url: `/api/v1/delegate/${id}`,
  method: 'PUT',
  data,
});

const deleteDelegateUser = (id) => requestWithToken({
  url: `/api/v1/delegate/${id}`,
  method: 'DELETE',
});

const LoginService = {
  authenticate,
  googleAuth,
  generateResetLink,
  verifyResetLink,
  assumeUser,
  resetPassword,
  refreshToken,
  callMe,
  inviteUser,
  deleteDelegateUser,
  updateUser
};

export default LoginService;
