import React from 'react';

export default props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">
            <g id="Group_42" data-name="Group 42" transform="translate(-1156 -1385)">
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="75" cy="75" r="75" transform="translate(1156 1385)" fill="#f4a859"/>
                <g id="support" transform="matrix(-0.966, -0.259, 0.259, -0.966, 1263.633, 1516.521)">
                <path id="Path_27" data-name="Path 27" d="M16.234,71.379a7.526,7.526,0,0,0-3.636.928A42.564,42.564,0,0,1,54.623,4.449,1.8,1.8,0,0,0,55.337.915,46.493,46.493,0,0,0,46.15,0,46.149,46.149,0,0,0,9.931,74.75a7.574,7.574,0,1,0,6.3-3.372Zm2.8,10.376a3.965,3.965,0,1,1,1.162-2.8A3.938,3.938,0,0,1,19.038,81.754Zm0,0" transform="translate(0 0)" fill="#fff"/>
                <path id="Path_28" data-name="Path 28" d="M46.766,11.769A7.556,7.556,0,1,0,44.1,14.215,42.563,42.563,0,0,1,2.157,82.089a1.8,1.8,0,0,0-.708,3.536,46.525,46.525,0,0,0,9.1.9A45.848,45.848,0,0,0,43.182,73,45.848,45.848,0,0,0,56.7,40.372a46.237,46.237,0,0,0-9.933-28.6Zm-9.105-1.393a3.967,3.967,0,1,1,2.8,1.161A3.94,3.94,0,0,1,37.661,10.376Zm0,0" transform="translate(35.601 5.778)" fill="#fff"/>
                <path id="Path_29" data-name="Path 29" d="M25.373,63.816a1.8,1.8,0,0,0,1.8,1.8H38.448a1.8,1.8,0,0,0,1.8-1.8V60.321a28.23,28.23,0,0,0,6.766-2.8l2.467,2.467a1.8,1.8,0,0,0,2.55,0L60,52.011a1.8,1.8,0,0,0,0-2.549L57.536,47a28.229,28.229,0,0,0,2.8-6.766h3.482a1.8,1.8,0,0,0,1.8-1.8V27.157a1.8,1.8,0,0,0-1.8-1.8H60.34a28.234,28.234,0,0,0-2.8-6.765l2.451-2.451a1.8,1.8,0,0,0,0-2.551L52.013,5.62a1.8,1.8,0,0,0-2.549,0L47.017,8.069a28.23,28.23,0,0,0-6.766-2.8V1.8a1.8,1.8,0,0,0-1.8-1.8H27.176a1.8,1.8,0,0,0-1.8,1.8V5.266a28.206,28.206,0,0,0-6.765,2.8L16.16,5.621a1.8,1.8,0,0,0-2.549,0L5.638,13.588a1.8,1.8,0,0,0,0,2.55l2.451,2.451a28.225,28.225,0,0,0-2.8,6.765H1.809a1.8,1.8,0,0,0-1.8,1.8L0,38.428a1.8,1.8,0,0,0,1.8,1.8H5.284A28.23,28.23,0,0,0,8.088,47L5.624,49.462a1.8,1.8,0,0,0,0,2.549l7.967,7.973a1.8,1.8,0,0,0,1.275.529h0a1.8,1.8,0,0,0,1.275-.528l2.467-2.467a28.254,28.254,0,0,0,6.766,2.8Zm-6.059-10.09a1.8,1.8,0,0,0-2.253.239L14.866,56.16,9.447,50.737l2.193-2.194a1.8,1.8,0,0,0,.239-2.253A24.675,24.675,0,0,1,8.465,38.05,1.8,1.8,0,0,0,6.7,36.626h-3.1l0-7.666H6.7a1.8,1.8,0,0,0,1.763-1.424A24.677,24.677,0,0,1,11.88,19.3a1.8,1.8,0,0,0-.239-2.253L9.461,14.863l5.423-5.419,2.177,2.177a1.8,1.8,0,0,0,2.253.239,24.665,24.665,0,0,1,8.24-3.415,1.8,1.8,0,0,0,1.424-1.763V3.605h7.666V6.683a1.8,1.8,0,0,0,1.424,1.763,24.686,24.686,0,0,1,8.24,3.415,1.8,1.8,0,0,0,2.253-.239l2.177-2.177,5.423,5.419-2.179,2.179a1.8,1.8,0,0,0-.239,2.253,24.691,24.691,0,0,1,3.415,8.24,1.8,1.8,0,0,0,1.763,1.424h3.1v7.666h-3.1a1.8,1.8,0,0,0-1.763,1.424,24.671,24.671,0,0,1-3.415,8.24,1.8,1.8,0,0,0,.239,2.253l2.193,2.193L50.758,56.16l-2.195-2.194a1.8,1.8,0,0,0-2.253-.239,24.692,24.692,0,0,1-8.24,3.415A1.8,1.8,0,0,0,36.645,58.9v3.11H28.979V58.9a1.8,1.8,0,0,0-1.424-1.763,24.677,24.677,0,0,1-8.24-3.415Zm0,0" transform="translate(13.337 13.34)" fill="#fff"/>
                <path id="Path_30" data-name="Path 30" d="M29.791,14.9a14.9,14.9,0,1,0-14.9,14.9A14.912,14.912,0,0,0,29.791,14.9Zm-26.186,0A11.29,11.29,0,1,1,14.9,26.186,11.3,11.3,0,0,1,3.605,14.9Zm0,0" transform="translate(31.254 31.254)" fill="#fff"/>
                <path id="Path_31" data-name="Path 31" d="M1.8,3.608a1.8,1.8,0,0,0,1.8-1.8A1.8,1.8,0,0,0,1.8,0a1.8,1.8,0,0,0,0,3.608Zm0,0" transform="translate(60.946 3.21)" fill="#fff"/>
                <path id="Path_32" data-name="Path 32" d="M1.8,0A1.8,1.8,0,0,0,0,1.8,1.8,1.8,0,0,0,3.077,3.077a1.8,1.8,0,0,0,0-2.549A1.817,1.817,0,0,0,1.8,0Zm0,0" transform="translate(27.827 85.514)" fill="#fff"/>
                </g>
            </g>
        </svg>

    )
}