import React from 'react';

export default props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">
        <g id="Group_44" data-name="Group 44" transform="translate(-886 -1385)">
          <circle id="Ellipse_2" data-name="Ellipse 2" cx="75" cy="75" r="75" transform="translate(886 1385)" fill="#dd1e2f"/>
          <g id="Group_24" data-name="Group 24" transform="translate(924.311 1423.311)">
            <path id="Path_15" data-name="Path 15" d="M40.742,36.69l31.8-31.8A2.866,2.866,0,0,0,68.484.84l-31.8,31.8L4.893.84A2.866,2.866,0,1,0,.839,4.894l31.8,31.8-31.8,31.8a2.866,2.866,0,0,0,4.054,4.054l31.8-31.8,31.8,31.8a2.866,2.866,0,0,0,4.054-4.054Z" transform="translate(0 -0.001)" fill="#fff"/>
          </g>
        </g>
      </svg>
    )
}