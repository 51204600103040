import { parse } from 'query-string';
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { _documentTitle } from '../../../../../utils/GlobalFunctions';
import CashFlow from './CashFlow';
//import ExpenseBreakdown from './ExpenseBreakdown';
import NetIncome from './NetIncome';
import Overdue from './Overdue';
import PayableOwing from './PayableOwing';
import ProfitLoss from './ProfitLoss';
import ThingsToDo from './ThingsToDo';
import { Helmet } from 'react-helmet'
import { checkVerifiedEmail } from '../../../../../constants';
import Icon from '../../../../common/Icon';
import symbolsIcon from '../../../../../assets/icons/product/symbols.svg'
import invoiceIcon from '../../../../../assets/icons/gradient-icons/ic-invoice.svg'
import recurringInvoiceIcon from '../../../../../assets/icons/gradient-icons/ic-recurring-invoice.svg'
import checkoutIcon from '../../../../../assets/icons/gradient-icons/ic-checkouts.svg'
import peymeIcon from '../../../../../assets/icons/gradient-icons/ic-peyme.svg'
import paymentsIcon from '../../../../../assets/icons/gradient-icons/ic-payments.svg'

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      limit: this.parseLimit(props),
    };
  }

  componentWillReceiveProps(props) {
    let limit = this.parseLimit(props);

    if (this.state.limit !== limit) {
      this.setState({ limit });
    }
  }

  componentDidMount() {
    const { businessInfo } = this.props;
    _documentTitle(businessInfo, 'Dashboard');
    checkVerifiedEmail()
  }

  parseLimit(props) {
    const { location: { search } } = props;
    const params = parse(search.substring(1));
    let limit = parseInt(params.limit || 'a');

    if (isNaN(limit)) {
      limit = undefined;
    }

    return limit;
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    const { limit } = this.state;
    const { businessInfo } = this.props;
    return (
      <Fragment>
        <Helmet>
          <meta name="viewport" content="" />
        </Helmet>        

        <div className="content-wrapper__main dashboard-wrapper">
          <header className="py-header--page d-flex flex-wrap">
            <div className="py-header--title">
              <h2 className="py-heading--title">Dashboard</h2>
            </div>
            <div className="py-header--actions d-flex flex-wrap">
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle caret color="primary" >Create a new</DropdownToggle>
                <DropdownMenu className="dropdown-menu-center">
                    <DropdownItem><NavLink to="/app/estimates/add">Estimate</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/invoices/add">Invoice</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/recurring/add">Recurring Invoice</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/purchase/bills/add">Bill</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/sales/customer/add">Customer</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/purchase/vendors/add">Vendor</NavLink></DropdownItem>
                    <DropdownItem><NavLink to="/app/sales/products/add">Product or Service</NavLink></DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </header>          
          <div className="dh-widget quick-link-widget">
            <div className="widget-box" >        
                <ul className="quick-link-list">
                  <li>
                    <NavLink to="/app/invoices" className={`icon-link`}>
                      <span className='gradient-icon'>                  
                        <img
                            src={invoiceIcon}
                            alt="Invoice Icon"
                            className="icon"
                        />
                      </span>
                      <span className='text'>Invoices</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/recurring" className={`icon-link`}>
                      <span className='gradient-icon'>                  
                        <img
                            src={recurringInvoiceIcon}
                            alt="Invoice Icon"
                            className="icon"
                        />
                      </span>
                      <span className='text'>Recurring Invoices</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/sales/checkouts" className={`icon-link`}>
                      <span className='gradient-icon'>                  
                        <img
                            src={checkoutIcon}
                            alt="Invoice Icon"
                            className="icon"
                        />
                      </span>
                      <span className='text'>Checkouts</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/peyme" className={`icon-link`}>
                      <span className='gradient-icon'>                  
                        <img
                            src={peymeIcon}
                            alt="Invoice Icon"
                            className="icon"
                        />
                      </span>
                      <span className='text'>PeyMe Lynk</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/payments/?status=success" className={`icon-link`}>
                      <span className='gradient-icon'>                  
                        <img
                            src={paymentsIcon}
                            alt="Invoice Icon"
                            className="icon"
                        />
                      </span>
                      <span className='text'>Payments</span>
                    </NavLink>
                  </li>
                </ul>
            </div>
          </div>
          <div className="content">
            <div className="activity-wrapper">
              <Overdue limit={limit} />
              {/* 
              Hide until accounting module not finished
              {businessInfo && businessInfo.country && businessInfo.country.name == "United States" && <BankAccounts limit={limit} />} 
              */}
              <ThingsToDo limit={limit} />
            </div>
            <div className="financial-wrapper">
              <CashFlow limit={limit} />
              <ProfitLoss limit={limit} />
              <PayableOwing limit={limit} />
              <NetIncome limit={limit} />
              {/* <ExpenseBreakdown limit={limit} /> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    businessInfo: state.businessReducer.selectedBusiness
  };
};

// export default Home
//  withStyles(styles)(Home);

// const mapDispatchToProps = (dispatch) => {
//     return {
//         actions: bindActionCreators(CustomerActions, dispatch)
//     };
// }

export default withRouter((connect(mapStateToProps, null)(Home)))
