export const ORGANIZATION_TYPE = [
    {
        label:'Individuals',
        value:'Individuals'
    },
    {
        label: 'Corporation',
        value: 'Corporation'
    },
    {
        label: 'Non-Profits',
        value: 'Non-Profits'
    }
]


export const BUSINESS_TYPE = [
    {
        value: 'Artists, Photographers & Creative Types',
        label: 'Artists, Photographers & Creative Types',
        options: [
            {
                value: 'atrists_photographers_creative__actor',
                label: 'Actor'
            },
            {
                value: 'atrists_photographers_creative__craftsperson',
                label: 'Craftsperson'
            },
            {
                value: 'atrists_photographers_creative__dancer_choreog',
                label: 'Dancer, Choreographer'
            },
            {
                value: 'atrists_photographers_creative__musician',
                label: 'Musician'
            },
            {
                value: 'atrists_photographers_creative__other',
                label: 'Other Creative'
            },
            {
                value: 'atrists_photographers_creative__photographer',
                label: 'Photographer'
            },
            {
                value: 'atrists_photographers_creative__visual_artist',
                label: 'Visual Artist'
            },
        ]
    },
    {
        value: 'Consultants & Professionals',
        label: 'Consultants & Professionals',
        options: [
            {
                value: 'consultants_professionals__accountants_bookkeepers',
                label: 'Accountant, Bookkeeper'
            },
            {
                value: 'consultants_professionals__communications',
                label: 'Communications, Marketing, PR'
            },
            {
                value: 'design_architecture_engineering',
                label: 'Design, Architecture, Engineering'
            },
            {
                value: 'consultants_professionals__executive_coach',
                label: 'Executive Coach'
            },
            {
                value: 'consultants_professionals__it_technical',
                label: 'IT, Technical'
            },
            {
                value: 'legal_services',
                label: 'Legal Services'
            },
            {
                value: 'consultants_professionals__other',
                label: 'Other Consultant'
            },
            {
                value: 'consultants_professionals__sales',
                label: 'Sales'
            },
        ]
    },
    {
        value: 'Financial Services',
        label: 'Financial Services',
        options: [
            {
                value: 'insurance_agency_broker',
                label: 'Insurance Agency, Broker'
            },
            {
                value: 'financial_services',
                label: 'Other Financial Service'
            }
        ]
    },
    {
        value: 'General: I make or sell a PRODUCT',
        label: 'General: I make or sell a PRODUCT',
        options: [
            {
                value: 'agriculture_ranching_farming',
                label: 'Agriculture, Ranching and Farming'
            },
            {
                value: 'product_provider__manufacturer',
                label: 'Manufacturer'
            },
            {
                value: 'product_provider__manufacturer_and_vendor',
                label: 'Manufacturer and Vendor'
            },
            {
                value: 'manufacturer_representative_agent',
                label: 'Manufacturing Representative, Agent'
            },
            {
                value: 'product_provider__other',
                label: 'Other Product-based Business'
            },
            {
                value: 'product_provider__vendor',
                label: 'Vendor'
            },
            {
                value: 'wholesale_distribution_sales',
                label: 'Wholesale Distribution and Sales'
            },
        ]
    },
    {
        value: 'General: I provide a SERVICE',
        label: 'General: I provide a SERVICE',
        options: [
            {
                value: 'automotive_sales_and_repair',
                label: 'Automotive Repair & Sales'
            },
            {
                value: 'service_provider__customer_service_support',
                label: 'Customer Service/Support'
            },
            {
                value: 'lodging_hotel_motel',
                label: 'Lodging, Hotel, Motel'
            },
            {
                value: 'service_provider__office_admin_support',
                label: 'Office Admin/Support'
            },
            {
                value: 'service_provider__other',
                label: 'Other Service-based business'
            },
            {
                value: 'service_provider__personal_care',
                label: 'Personal Care'
            },
            {
                value: 'repair_and_maintenance',
                label: 'Repairs/Maintenance'
            },
            {
                value: 'restaurant_caterer_bar',
                label: 'Restaurant, Caterer, Bar'
            },
            {
                value: 'service_provider__telemarketing',
                label: 'Telemarketing'
            },
            {
                value: 'service_provider__transcription',
                label: 'Transcription'
            },
            {
                value: 'transportation_trucking_delivery',
                label: 'Transportation, Trucking Or Delivery'
            },
        ]
    },
    {
        value: 'Hair, Spa & Aesthetics',
        label: 'Hair, Spa & Aesthetics',
        options: [
            {
                value: 'hair_spa_aesthetics__massage',
                label: 'Massage'
            },
            {
                value: 'hair_spa_aesthetics__nail_skin_aesthetics',
                label: 'Nails, Skin, Aesthetics'
            },
            {
                value: 'hair_spa_aesthetics__other',
                label: 'Other Aesthetics/Spa'
            },
            {
                value: 'hair_spa_aesthetics__hair_salon',
                label: 'Salon, Spa'
            }
        ]
    },
    {
        value: 'Medical, Dental, Health',
        label: 'Medical, Dental, Health',
        options: [
            {
                value: 'medical_dental_health_service__chiropractor',
                label: 'Chiropractor'
            },
            {
                value: 'medical_dental_health_service__dentist',
                label: 'Dentist'
            },
            {
                value: 'medical_dental_health_service__massage_therapist',
                label: 'Massage Therapist'
            },
            {
                value: 'medical_dental_health_service__mental_health',
                label: 'Mental Health'
            },
            {
                value: 'medical_dental_health_service__occup_therapist',
                label: 'Occupational Therapist'
            },
            {
                value: 'medical_dental_health_service__other',
                label: 'Other Health'
            },
            {
                value: 'medical_dental_health_service__physical_therapist',
                label: 'Physical Therapist '
            },
        ]
    },
    {
        value: 'Non-profits, Associations & Groups',
        label: 'Non-profits, Associations & Groups',
        options: [
            {
                value: 'nonprofit_associations_groups__association',
                label: 'Association'
            },
            {
                value: 'nonprofit_associations_groups__charitable',
                label: 'Charity'
            },
            {
                value: 'church_religious_organization',
                label: 'Church, Religious Organization'
            },
            {
                value: 'nonprofit_associations_groups__club',
                label: 'Club'
            },
            {
                value: 'nonprofit_associations_groups__other',
                label: 'Other Non-Profit'
            },
        ]
    },
    {
        value: 'Real Estate, Construction & Home Improvement',
        label: 'Real Estate, Construction & Home Improvement',
        options: [
            {
                value: 'construction_home_improvement__contractor',
                label: 'Contractor'
            },
            {
                value: 'construction_home_improvement__engineer',
                label: 'Engineer'
            },
            {
                value: 'construction_home_improvement__home_inspector',
                label: 'Home Inspector'
            },
            {
                value: 'landlord_property_manager__landlord',
                label: 'Landlord'
            },
            {
                value: 'lawn_care_landscaping',
                label: 'Lawn Care, Landscaping'
            },
            {
                value: 'real_estate_sales__other',
                label: 'Other Real Estate'
            },
            {
                value: 'landlord_property_manager__property_manager',
                label: 'Property Manager'
            },
            {
                value: 'real_estate_sales__agent',
                label: 'Real Estate Agent'
            },
            {
                value: 'real_estate_sales__broker',
                label: 'Real Estate Broker'
            },
            {
                value: 'rental',
                label: 'Real Estate Rental'
            },
            {
                value: 'construction_home_improvement__other_trades',
                label: 'Trade'
            }
        ]
    },
    {
        value: 'Retailers, Resellers & Sales',
        label: 'Retailers, Resellers & Sales',
        options: [
            {
                value: 'retailers_and_resellers__ebay',
                label: 'eBay Resellers'
            },
            {
                value: 'retailers_and_resellers__etsy',
                label: 'Etsy Vendors'
            },
            {
                value: 'retailers_and_resellers__non_store_retailer',
                label: 'Non-Store Retailers'
            },
            {
                value: 'retailers_and_resellers__other',
                label: 'Other Retailers'
            },
            {
                value: 'sales_independent_agent',
                label: 'Sales: Independent Agent'
            },
            {
                value: 'retailers_and_resellers__store_retailer',
                label: 'Store Retailers'
            },
        ]
    },
    {
        value: 'Web, Tech & Media',
        label: 'Web, Tech & Media',
        options: [
            {
                value: 'advertising_public_relations',
                label: 'Advertising, Public Relations'
            },
            {
                value: 'web_media_freelancer__designer',
                label: 'Designer'
            },
            {
                value: 'web_media_freelancer__marketing_social_media',
                label: 'Marketing, Social Media'
            },
            {
                value: 'web_media_freelancer__other',
                label: 'Other Media/Tech'
            },
            {
                value: 'web_media_freelancer__programmer',
                label: 'Programmer'
            },
            {
                value: 'web_media_freelancer__seo',
                label: 'SEO'
            },
            {
                value: 'web_media_freelancer__writer',
                label: 'Writer'
            }
        ]
    }
]

export const organizationType = []