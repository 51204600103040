import React, { useState, useEffect } from 'react';
import { Spinner, Tooltip } from 'reactstrap';
import history from '../../customHistory'

const PlanDetails = ({ plans, getSelectedPlan, isLoading, getCardDetails, planId, buttonText, businessInfo, planType }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [isDowngrade, setIsDowngrade] = useState(false)
  const [existingPlan, setExistingPlan] = useState(null)
  useEffect(() => {
    const getRecommended = plans.find((plan) => planType ? planType == plan.planLevel : planId ? plan._id === planId : plan.isRecomended)
    if (plans && businessInfo && businessInfo.subscription && businessInfo.subscription.planId) {
      const getExistingPlanLevel = plans.find((plan) => plan._id === businessInfo.subscription.planId)
      setExistingPlan(getExistingPlanLevel)
      setIsDowngrade(getRecommended.planLevel < getExistingPlanLevel.planLevel)
    }
    getSelectedPlan(getRecommended)
    setSelectedPlan(getRecommended)
  }, [plans])


  const handleChange = (e) => {
    const getChangedPlan = plans.find((plan) => plan.title === e.target.value)
    getSelectedPlan(getChangedPlan)
    setSelectedPlan(getChangedPlan)
  }

  const handleToggle = (target) => {
    setTooltipOpen({
      [target]: !tooltipOpen[target]
    })
  };

  return (
    <div className="price-box">
      <div className="price-header d-lg-flex flex-wrap">
        {plans && !planId && selectedPlan && plans.map((plan) => (<label kye={plan._id} class="py-radio me-1">
          <input type="radio" name="pricePlan" checked={selectedPlan.title === plan.title} onChange={handleChange} value={plan.title} />
          <span class="py-form__element__faux"></span>
          <span class="py-form__element__label">{plan.title}</span>
        </label>))}
        {plans && planId && selectedPlan && <div className="price-name-area" >
          <h4 className="plan-name" >{selectedPlan.title}</h4>
          <div className="different-plan" onClick={() => history.goBack()}>Choose different plan</div>
        </div>}
      </div>
      <div className="price-body" >
        {selectedPlan && selectedPlan.features && <ul className="price-features">
          {selectedPlan.features.map((feature, i) => (
            <li key={`${feature.title}-${i}`}><i className="py-check Icon"></i>
              <span dangerouslySetInnerHTML={{__html: feature.title}} />
              {feature.info && <a href="#" id={`Tooltip-${selectedPlan._id}-${i}`} className="info-ico ps-1" ><i class="fal fa-info-circle"></i>
                <Tooltip placement="top" isOpen={tooltipOpen[`Tooltip-${selectedPlan._id}-${i}`]} target={`Tooltip-${selectedPlan._id}-${i}`}
                  toggle={() => handleToggle(`Tooltip-${selectedPlan._id}-${i}`)}>
                  {feature.info}
                </Tooltip></a>}</li>
          ))}
        </ul>}
        {isDowngrade && (selectedPlan.planLevel ==1 && existingPlan.planLevel==3) && planId && plans && plans[1].features && <ul className="price-features" key={existingPlan}>
          {plans[1].features.map((feature, i) => (
            i != 0 && <React.Fragment >
              <li key={`${feature.title}-${i}`}><i className="fa fa-times Icon"></i> {feature.title}
                {feature.info && <a href="#" id={`Tooltip-${existingPlan._id}-${i}`} className="info-ico ps-1" ><i class="fal fa-info-circle"></i>
                  <Tooltip placement="top" isOpen={tooltipOpen[`Tooltip-${existingPlan._id}-${i}`]} target={`Tooltip-${existingPlan._id}-${i}`}
                    toggle={() => handleToggle(`Tooltip-${existingPlan._id}-${i}`)}>
                    {feature.info}
                  </Tooltip></a>}</li>
            </React.Fragment>
          ))}
        </ul>}
        {isDowngrade && planId && existingPlan && existingPlan.features && <ul className="price-features" key={existingPlan}>
          {existingPlan.features.map((feature, i) => (
            i != 0 && <React.Fragment >
              <li key={`${feature.title}-${i}`}><i className="fa fa-times Icon"></i> {feature.title}
                {feature.info && <a href="#" id={`Tooltip-${existingPlan._id}-${i}`} className="info-ico ps-1" ><i class="fal fa-info-circle"></i>
                  <Tooltip placement="top" isOpen={tooltipOpen[`Tooltip-${existingPlan._id}-${i}`]} target={`Tooltip-${existingPlan._id}-${i}`}
                    toggle={() => handleToggle(`Tooltip-${existingPlan._id}-${i}`)}>
                    {feature.info}
                  </Tooltip></a>}</li>
            </React.Fragment>
          ))}
        </ul>}

        {selectedPlan && <div className="price-total">${selectedPlan.price.toLocaleString()} USD</div>}
      </div>
      {selectedPlan && selectedPlan.planLevel == 1 && <div className="col-12 mt-4 px-0">
        <button className="btn btn-primary btn-block" disabled={isLoading} type="button" onClick={getCardDetails} >{buttonText} {isLoading && (<Spinner size="sm" color="default" />)}</button>
      </div>}
    </div>
  );
}

export default PlanDetails;