import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MainRoute from '../../../../components/app/MainRoute'
import Payment from '.';
import OnBoarding from './components/onBoarding/';
import PaymentOnboarding from '../PaymentOnBoarding/index'
import ExpressOnBoarding from './components/expressOnBoarding/';
import ViewPayment from './components/viewPayment';
import ViewRefund from './components/viewRefund';
import KycError from './components/KycError';
import OnBoardPhoneVerification from './components/onBoardPhoneVerification'
// import NotFound from '../../../../components/404'
export function PaymentRoutes(url) {
    return (
        <Switch>
            <MainRoute exact path={`${url}`} component={Payment} />
            {/* <MainRoute exact path={`${url}/onboarding`} component={OnBoarding} /> */}
            <MainRoute exact path={`${url}/onboarding`} component={PaymentOnboarding} />
            <MainRoute exact path={`${url}/express-onboarding`} component={ExpressOnBoarding} />
            <MainRoute exact path={`${url}/kyc`} component={KycError} />
            <MainRoute exact path={`${url}/verify-phone`} component={OnBoardPhoneVerification} />
            {/* Route for details of refunds under particular Payment by payment ID  */}
            <MainRoute exact path={`${url}/:id/refunds`} component={ViewRefund} />
            {/* Route for view refund detail by refund ID  */}
            <MainRoute exact path={`${url}/refunds/:id`} component={ViewRefund} />
            {/* Route for details of original Payment  */}
            <MainRoute exact path={`${url}/view-payment/:id`} component={ViewPayment} />
            {/* <Route component={NotFound} /> */}
        </Switch>
    )
};
