import {
  HTTP_GET,
  HTTP_POST
} from "../components/app/components/purchase/Components/bills/constants/BillFormConstants";
import request from "./request";
import requestWithToken from "./requestWithToken";

// Get list of all plans
export const getPlans = async () => {
  return request({
    url: `/api/v1/plans`,
    method: HTTP_GET
  });
}