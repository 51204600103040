import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getPlans } from '../../api/plansService'
import {
  createCustomerAndSubscription,
  updateSubscription,
  applyCoupon
} from '../../api/subscriptionService'
import { getPaymentCards } from '../../api/subscriptionService'
import CenterSpinner from '../../global/CenterSpinner'
import BrowserTabTitle from '../../global/browserTabTitle'
import { openGlobalSnackbar } from '../../actions/snackBarAction'
import { setSelectedBussiness } from '../../actions/businessAction'
import StripeCardForm from '../stripeCardForm'
import PlanDetails from './planDetails'
import ActiveCard from './activeCard'
import history from '../../customHistory'

const Index = ({
  from = null,
  planId = null,
  businessInfo,
  showSnackbar,
  setSelectedBussiness,
  planType = null
}) => {
  const [plans, setPlans] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [activeCard, setActiveCard] = useState(false)
  const [cards, setCards] = useState(null)
  useEffect(() => {
    if (localStorage.getItem('businessId')) {
      initialFun()
    } else {
      history.push('signin')
    }
  }, [])

  const handleDifferentCard = () => {
    setActiveCard(!activeCard)
  }

  const initialFun = async () => {
    if (planId) {
      const getCards = await getPaymentCards()
      setCards(getCards.data)
      if (getCards.data.length > 0) {
        setActiveCard(true)
      }
    }

    const plansResponse = await getPlans()
    setPlans(plansResponse.data)
  }

  const getSelectedPlan = data => {
    setSelectedPlan(data)
  }
  const handleSubscribe = async (data = null) => {
    setIsLoading(true)
    try {
      const dataObj = {
        paymentMethodId: data.paymentMethodId || 'Starter',
        cardHolderName: data.cardHolderName || 'Starter',
        planId: selectedPlan._id,
        businessId: localStorage.getItem('businessId'),
        userId: localStorage.getItem('user.id'),
        couponCode: data.couponCode ? data.couponCode : null
      }
      let response

      // Check if planId for update subscription
      if (
        planId &&
        businessInfo &&
        businessInfo.subscription &&
        businessInfo.subscription.isSubscribed
      ) {
        const { _id } = businessInfo.subscription
        dataObj['cardHolderName'] = data.cardHolderName
          ? data.cardHolderName
          : ''
        dataObj['paymentMethodId'] = data.paymentMethodId
          ? data.paymentMethodId
          : ''
        dataObj['subscriptionId'] = _id
        delete dataObj.businessId
        response = await updateSubscription(dataObj)
      } else {
        // create subscription
        response = await createCustomerAndSubscription(dataObj)
      }
      if (response.statusCode === 201 || response.statusCode === 200) {
        if (planId) {
          const authToken = localStorage.getItem('token')
          setSelectedBussiness(
            localStorage.getItem('businessId'),
            authToken,
            false
          )
          showSnackbar(response.message, false)
          history.push('/app/setting/subscription-history')
        } else {
          history.push('thankyou')
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
        showSnackbar(response.message, false)
        throw Error(response.message)
      }
    } catch (error) {
      setIsLoading(false)
      showSnackbar(error.message, false)
    }
  }



  const handleMessage = (data, type) => {
    showSnackbar(data, type)
  }

  return (
    <div className={`${from == 'update' ? '' : 'subs-checkout'}`}>
      <BrowserTabTitle title="Subscription" />
      {plans ? (
        <React.Fragment>
          <div className="row mb-3 mb-lg-4">
            <div class="py-header--title mt-0 col-12">
              <h2 class="py-heading--title">Subscription details</h2>
            </div>
          </div>
          <div className="row mx-n4">
            <div
              className={`${selectedPlan && selectedPlan.planLevel === 1
                  ? 'col-lg-12 starter-col'
                  : 'col-lg-5'
                } px-4 mb-4`}
            >
              <PlanDetails
                plans={plans}
                getSelectedPlan={getSelectedPlan}
                isLoading={isLoading}
                getCardDetails={handleSubscribe}
                planId={planId}
                buttonText={from == 'update' ? 'Modify Plan' : 'Get Started'}
                businessInfo={businessInfo}
                planType={planType}
              />
            </div>
            <div
              className={`col-lg-7 px-4 ${selectedPlan && selectedPlan.planLevel !== 1
                  ? 'd-block'
                  : 'd-none'
                }`}
            >
              {activeCard ? (
                <ActiveCard
                  type="subscribe"
                  buttonText="Modify Plan"
                  isLoading={isLoading}
                  cards={cards}
                  getCardDetails={data => handleSubscribe(data)}
                  handleDifferentCard={handleDifferentCard}
                />
              ) : (
                  <StripeCardForm
                    type="subscribe"
                    buttonText={
                      from == 'update' ? 'Modify Plan' : 'Subscribe now'
                    }
                    showDisclaimer={true}
                    isLoading={isLoading}
                    cards={cards}
                    planDetails={selectedPlan}
                    showMessage={handleMessage}
                    handleDifferentCard={handleDifferentCard}
                    getCardDetails={data => handleSubscribe(data)}
                  />
                )}
            </div>
          </div>
        </React.Fragment>
      ) : (
          <div className="mx-auto py-5">
            <CenterSpinner />
          </div>
        )}
    </div>
  )
}

const mapPropsToState = ({ businessReducer }) => ({
  businessInfo: businessReducer.selectedBusiness
})

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, error) => {
      dispatch(openGlobalSnackbar(message, error))
    },
    setSelectedBussiness: (data, token, redirect) => {
      dispatch(setSelectedBussiness(data, token, redirect))
    }
  }
}
export default connect(mapPropsToState, mapDispatchToProps)(Index)
