import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  Container
} from 'reactstrap'
import history from '../../../../customHistory'
import queryString from 'query-string'

import {
  getAllPayments,
  getAllRefunds,
  postNewRefund,
  getPayoutBalance
} from '../../../../actions/paymentAction'
import { openGlobalSnackbar } from '../../../../actions/snackBarAction';
import { getOnboardingStatus } from '../../../../actions/paymentAction';
import { fetchPaymentSettings } from '../../../../actions/paymentSettings';
import PaymentInit from './components/onBoardingInit';
import PaymentRecords from './components/paymentRecords';
import CenterSpinner from '../../../../global/CenterSpinner';
import { getQueryStringUrl } from '../../../../utils/common';

const styles = theme => ({})

class Payment extends PureComponent {
  state = {
    filter: {
      startDate: '',
      endDate: '',
      text: '',
      pageNo: 1
    },
    loadListData: true
  }

  async componentDidMount() {
    const { businessInfo } = this.props

    await this.props.getPaymentSettings();
    if(!this.props.isPeyme){
    document.title = businessInfo
      ? `Peymynt - ${businessInfo.organizationName} - Payments`
      : 'Peymynt - Payments'}

    if (this.props.paymentIntermediateData) {
      history.push('/app/payments/onboarding')
    }

  }

  componentDidUpdate(prevProps) {
    const { data } = this.props.paymemntSettings;
    if (data.isVerified && data.isVerified.payment && this.state.loadListData) {
      this.setState({ loadListData: false })
      const queryFilter = {};
      if (this.props.location) {
        const query = queryString.parse(this.props.location.search);
        if (query.startDate) {
          queryFilter.startDate = query.startDate;
        }
        if (query.endDate) {
          queryFilter.endDate = query.endDate;
        }
        if (query.text) {
          queryFilter.text = query.text;
        }
        let queryData = Object.entries(query).map(([key, val]) => `${key}=${val}`).join("&");
        const pathname = this.props.location.pathname;
        history.push({
          pathname,
          search: queryData
        });
      }
      this._fetchPayments({...this.state.filter, ...queryFilter});
    }
    if(prevProps.refreshPayment !== this.props.refreshPayment){
      this._fetchPayments(this.state.filter);
    }
  }

  _fetchPayments = filter => {
    if (this.props.isPeyme) {
      const { pageNo } = this.state.filter
      const data = {
        peymeId: this.props.peymeName,
        pageNo
      }
      this.props.getPayment(data)
    }else if (this.props.location.search.includes('?checkoutId=')) {
      const { pageNo } = this.state;
      const data = {
        checkoutId: this.props.location.search.split('?checkoutId=')[1],
        pageNo: filter.pageNo ? filter.pageNo : pageNo
      }
      this.props.getPayment(data)
    } else if (this.props.location.search.includes('?pageNo=')) {
      const { pageNo } = this.state;
      const data = {
        pageNo: getQueryStringUrl('?pageNo=')
      }
      this.props.getPayment(data)
    } else {
      this.props.getPayment(filter)
    }
    this.setState({ filter: filter })
  }

  redirectToOnBoarding() {
    history.push('/app/payments/onboarding')
  }
  redirectToExpressOnBoarding() {
    history.push('/app/payments/express-onboarding')
  }

  redirectToKyc() {
    history.push('/app/payments/kyc')
  }

  render() {
    const statusParams = !this.props.isPeyme && new URLSearchParams(this.props.location.search)
    const {
      paymentList,
      refundList,
      statusCode,
      paymentMeta,
      refundMeta,
      payoutBalance,
      paymemntSettings: { data, loading },
      message
    } = this.props;
    if (loading) {
      return (
        <Container className="mrT50 text-center">
          <CenterSpinner />
        </Container>
      )
    } else if (data && (!data.isConnected || !data.isOnboardingApplicable)) {
      return <PaymentInit {...this.props} />
    }
    else if (data && data.isConnected && !data.isSetupDone && data.charges.expressSupported && data.charges.expressEnabled) {
      return <Fragment>{this.redirectToExpressOnBoarding()}</Fragment>
    } else if (data && data.isConnected && !data.isVerified.payment && !data.isSetupDone) {
      return <Fragment>{this.redirectToOnBoarding()}</Fragment>
    }else if(data && data.onboardingStatus === "rejected"){
      return <Fragment>{this.redirectToOnBoarding()}</Fragment>
    }
    else if(data && data.isSetupDone && (data.onboardingStatus === "awaiting_approval" || data.kycStatus !== 'verified')) {
      return <Fragment>{this.redirectToKyc()}</Fragment>
    }
    else if (
      (!Array.isArray(paymentList) || paymentList.length >= 0) &&
      statusCode === 200 && data && data.isSetupDone && data.isVerified.payment
    ) {
      return (
        <PaymentRecords
          data={paymentList}
          paymentMeta={paymentMeta}
          refundMeta={refundMeta}
          refundList={refundList}
          fetchData={this._fetchPayments}
          getRefund={this.props.getRefund}
          tabSelected={statusParams && statusParams.get("status")  ? `payments.${statusParams.get("status")}` : this.props.match ? this.props.match.params.status : ""}
          filter={this.state.filter}
          isPeyme={this.props.isPeyme}
          isLoaing={!this.props.paymentDataLoaded}
          bankingSetupSkipped={data.bankingSetupSkipped}
          getPayoutBalance={this.props.getPayoutBalance}
          payoutBalance={payoutBalance}
          showSnackbar={this.props.showSnackbar}
          payoutSetting={data.payoutSetting}
        />
      )
    } else {
      return (
        <Container className="mrT50 text-center">
          <CenterSpinner />
        </Container>
      )
    }

  }
}

const mapStateToProps = state => {

  return {
    paymentList: state.paymentReducer.paymentRecords,
    refundList: state.paymentReducer.refundRecords,
    paymentMeta: state.paymentReducer.paymentData ? state.paymentReducer.paymentData.meta : null,
    paymentIntermediateData: state.paymentReducer.paymentIntermediateData,
    paymentDataLoaded: state.paymentReducer.paymentDataLoaded,
    statusCode: state.paymentReducer.statusCode,
    message: state.paymentReducer.message,
    businessInfo: state.businessReducer.selectedBusiness,
    paymemntSettings: state.paymentSettings,
    refundMeta: state.paymentReducer.refundMeta,
    payoutBalance: state.paymentReducer.payoutBalance
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPayment: body => {
      dispatch(getAllPayments(body))
    },
    getRefund: body => {
      dispatch(getAllRefunds(body))
    },
    fetchOnBoarding: () => {
      dispatch(getOnboardingStatus())
    },
    postRefund: body => {
      dispatch(postNewRefund(body))
    },
    getPaymentSettings: body => {
      dispatch(fetchPaymentSettings(body))
    },
    getPayoutBalance: body => {
      dispatch(getPayoutBalance(body));
    },
    showSnackbar: (message, error) => {
      dispatch(openGlobalSnackbar(message, error));
    },
  }
}

export default compose(
  withStyles(styles, { name: 'Payment' }),
  connect(mapStateToProps, mapDispatchToProps)
)(Payment)
