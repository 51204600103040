import React from 'react';
import { Link } from 'react-router-dom';

const Index = () => {
 return (
  <div className="subscription-vcenter" >
    <div className="upgrad-plan-area-box" >
      <h3 className="upgrad-title" >You are currently subscribed to the <span className="text-primary" >Starter</span> plan</h3>
      <div className="upgrad-desc" >Click the upgrade plan button to modify your plan</div>
      <Link className="btn btn-primary" to="/app/setting/subscription-plans" >Upgrade plan</Link>
    </div>
  </div>
 )
}

export default Index;