import React, { PureComponent } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Login from './index';

class LoginForm extends PureComponent {
  render() {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    )
  }
}

export default LoginForm;