import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom';
import CrediCards from '../creditCards';
export default class MethodPopup extends Component {
    render() {
        const { modeCard, modeBank, creditCardDisabled, bankModeDisabled } = this.props
        return (
            <Fragment>
                <button className="py-popup-close" onClick={this.props.toggle}>
                    <i className="fal fa-times" /></button>
                <div className="py-popup-content-wrapper">
                    <h6>Allow my customer to pay this invoice via:</h6>
                    <div className="payOpt">
                        <div className="row">
                            <div className="toggle-handle">
                                <div className={`switch ${creditCardDisabled ? 'disabled' : ''}`}>
                                    <input type="checkbox"
                                        id="card"
                                        name={"modeCard"}
                                        checked={modeCard}
                                        onChange={(e) => this.props.handleChange(e)}
                                        disabled={creditCardDisabled}
                                    />
                                    <label htmlFor="card"><span className="round-btn"></span></label>
                                </div>
                                <span className="txt">Credit card</span>
                            </div>
                            <CrediCards cards={['cc-visa', 'cc-mastercard', 'cc-amex', 'cc-discover']} />
                        </div>
                        {/* Remove comment code while bank payment is enabled */}
                        {/* <div className="row">
                            <div className="toggle-handle">
                                <div className={`switch ${bankModeDisabled ? 'disabled' : ''}`}>
                                    <input
                                        type="checkbox"
                                        id="bank"
                                        name={"modeBank"}
                                        checked={modeBank}
                                        onChange={(e) => this.props.handleChange(e)}
                                        disabled={bankModeDisabled}
                                    />
                                    <label htmlFor="bank"><span className="round-btn"></span></label>
                                </div>
                                <span className="txt">Bank Payment</span>
                            </div>
                            <div className="Icons cards">
                                <span className="Icon bankofamerica card"></span>
                                <span className="Icon chase card"></span>
                                <span className="Icon citi card"></span>
                                <span className="Icon wellsfargo card"></span>
                                <p className="note">&amp; 2,400+ others</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="changePref">
                        <span>Want to change payment options on future invoices?</span>
                        <NavLink to="/app/setting/payments">Change your preferences</NavLink>
                    </div>
                </div>
            </Fragment>
        )
    }
}
