import { BusinessTypeInputMask,BusinessPhoneMask, DisplayMask,TermsAndConditionMask ,DobMask,Numbermask,Numberformatmask} from "./components";

const uiSchema = {
    businessType: {
      'ui:widget': BusinessTypeInputMask
    },
    telephone: {
      'ui:widget': BusinessPhoneMask
    },
    personalPhone: {
      'ui:widget': BusinessPhoneMask
    },
    statement: {
      displayName: {
        'ui:widget': DisplayMask
      }
    },
    merchantAgreementAccepted : {
      'ui:widget': TermsAndConditionMask,
    },
    dob: {
      'ui:widget': DobMask
    },
    businessIncorporationDate: {
      'ui:widget': DobMask
    },
    idNumber: {
      'ui:widget': Numbermask
    },
    taxNumber: {
      'ui:widget': Numbermask
    },
    annualCardVolume: {
      'ui:widget': Numberformatmask
    },
    maxTransactionAmount: {
      'ui:widget': Numberformatmask
    }
  }

  const uiSchema1 = {
    items: {
      personalPhone: {
        'ui:widget': BusinessPhoneMask
      },
      dob: {
        'ui:widget': DobMask
      },
      idNumber: {
        'ui:widget': Numbermask
      }
    }
  }
  export {uiSchema,uiSchema1};