import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import history from '../../../../customHistory'
import { openGlobalSnackbar } from '../../../../actions/snackBarAction'
import {
    addBodyToPayment,
    getOnboardingStatus,
    skipStepOnboarding,
    updateStatusStepThree
} from '../../../../actions/paymentAction';
import {
    getBusinessMcc
} from '../../../../actions/utilityAction';
import { fetchPaymentSettings } from '../../../../actions/paymentSettings';
import Stepper from './onBoarding/Stepper';
import OnBoarding from './onBoarding/index';
import PaymentService from '../../../../api/paymentService';

class PaymentOnboarding extends PureComponent {
    state = {
        activeStep: 0,
        isReadOnly: false,
        isOnload: true,
        onBoardingData: {},
        stepperData: [],
        newOwnerflag : false,
        formData : {},
        additionaFieldData : [],
        visitedStep : [],
        currentStep: "",
        onboardingStatus : "",
        remarks:''
    }

   async componentDidMount() {
        const { data } = this.props.paymemntSettings;
        if(data.onboardingStatus === 'rejected'){
                this.checkStage();
        }else{
            if (!data.isVerified.payment && !data.isSetupDone && data.isConnected) {
                this.checkStage();
            } else {
                history.push('/app/payments')
            }
        }
        this.setState(prevState => ({
            visitedStep: [...prevState.visitedStep,1]
        }))
        this.setState({
            onboardingStatus : data.onboardingStatus,
            currentStep : 1
        })
        const onBoardingStepData = await PaymentService.fetchPaymentOnboardingSteps(1);
        if(onBoardingStepData.data){
            this.setState({
                onBoardingData: onBoardingStepData.data.stepSchema,
                formData: onBoardingStepData.data.formData,
                stepperData: onBoardingStepData.data.metaData.titleNew || onBoardingStepData.data.metaData.title
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.onboardingBody !== this.props.onboardingBody){
            this.setState({
                remarks: this.props.onboardingBody.remarks || '',
            })
        }
    }
    checkStage = async () => {
        try {
            this.setState({isReadOnly:false})
            await this.props.fetchOnBoarding();
        } catch (error) { }
    }

    onShowSnackbar = message => {
        this.props.showSnackbar(message, true)
    }

    disablestep = () =>{
        history.push("/app/payments/")
        this.setState({
            visitedStep: []
        })
    }

    handleSteps = async (activeStep) => {
        this.setState({
            currentStep : activeStep + 1
        })
        const onBoardingStepData = await PaymentService.fetchPaymentOnboardingSteps(activeStep + 1);
        if(!(this.state.visitedStep.includes(activeStep + 1)) ){
            this.setState(prevState => ({
                visitedStep: [...prevState.visitedStep, activeStep + 1]
            }))
        }
        if(this.state.visitedStep.includes(activeStep+1)){
            this.setState({
                formData : onBoardingStepData.data.formData
            })
        }

        if(onBoardingStepData && onBoardingStepData.data){
            this.setState({
                onBoardingData: onBoardingStepData.data.stepSchema,
            })
        }
        this.setState({ activeStep, isOnload: false })
    }


    render() {
        const { activeStep, isReadOnly } = this.state;
        const { onboardingBody } = this.props;

        return (
            <div id="Onboarding" className="content-wrapper__main">
                {this.state.onboardingStatus === 'rejected' && this.state.remarks !== '' ? (<div className='text-center mb-5'><b>Remarks:</b> <span>{this.state.remarks}</span></div>) :""}
                <div className="container">
                    <div className="row mx-n2">
                        <div className="col-md-3 px-2">
                            <Stepper
                                activeStep={activeStep}
                                handleSteps={this.handleSteps}
                                stepperData={this.state.stepperData}
                                visitedStep={this.state.visitedStep}
                                currentStep={this.state.currentStep}
                            />
                        </div>
                        <div className="col-md-9 px-2">
                            <div className="content">
                                <div className="payment__onboarding__container">
                                    <div className="payment__onboarding__content text-center">
                                        <OnBoarding 
                                            handleSteps={this.handleSteps} 
                                            activeStep={activeStep}
                                            formData={this.state.formData}
                                            isReadOnly={isReadOnly}
                                            stepperData={this.state.stepperData}
                                            additionaFieldData={this.state.additionaFieldData}
                                            onShowSnackbar={this.onShowSnackbar}
                                            onBoardingData={this.state.onBoardingData}
                                            checkStage={this.props.getPaymentSettings}
                                            newOwnerflag={this.state.newOwnerflag}
                                            disablestep={this.disablestep}
                                            {...this.props}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        onboardingBody: state.paymentReducer.onboardingBody,
        selectedBusiness: state.businessReducer.selectedBusiness,
        loading: state.paymentReducer.loading,
        paymemntSettings: state.paymentSettings,
        getAllMCC: state.getAllMCC,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        patchOnboarding: bindActionCreators(addBodyToPayment, dispatch),
        fetchOnBoarding: () => {
            dispatch(getOnboardingStatus())
        },
        showSnackbar: (message, error) => {
            dispatch(openGlobalSnackbar(message, error))
        },
        getPaymentSettings: body => {
            dispatch(fetchPaymentSettings(body))
        },
        getBusinessMcc: body => {
            dispatch(getBusinessMcc())
        },
        skipStep: body => {
            dispatch(skipStepOnboarding())
        },
        updateStepThree: body => {
            dispatch(updateStatusStepThree())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOnboarding);
