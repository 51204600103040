import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Input,
  InputGroup,
  Form,
  FormGroup,
  InputGroupText,
  Button,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { FormControl } from 'react-bootstrap'
import Cards from '../../../../../global/Card'
import {
  _setCurrency,
} from '../../../../../utils/GlobalFunctions'
import SelectBox from '../../../../../utils/formWrapper/SelectBox';
import { fetchBusinessCheckoutFee } from '../../../../../api/CheckoutService'
import { calculateBusinessProcessingFee } from '../../../../../utils/common'
import { Spinner } from 'reactstrap';
import FormValidationError from '../../../../../global/FormValidationError';
import GetRedirectUrl from '../common/paymentTypeRedirection'

const refundReasons = [
  { label: 'Customer complaint', value: "Customer complaint" },
  { label: 'Duplicate payment', value: "Duplicate payment" },
  { label: 'Other', value: "Other" },
];

export const RefundModal = props => {
  const [reason, setReason] = useState(null)
  const [reasonErr, setReasonErr] = useState(false)
  const [amountErr, setAmountErr] = useState(false)
  const [refundType, setRefundType] = useState('full')
  const [includeProcessingFee, setIncludeProcessingFee] = useState(false)
  const [amount, setAmount] = useState((parseFloat(props.data.amountBreakup && props.data.amountBreakup.total) - parseFloat(props.data.refund.totalAmount)).toFixed(2))
  const [refundAmount, setRefundAmount] = useState((parseFloat(props.data.amountBreakup && props.data.amountBreakup.total) - parseFloat(props.data.refund.totalAmount)).toFixed(2))
  const [notes, setNotes] = useState('')
  const [businessFee, setBusinessFee] = useState([])

  useEffect(() => {
    if (refundType === 'full') {
      setIncludeProcessingFee(true)
    }
  }, [refundType]);

  useEffect(() => {
    fetchBusinessFee();
  }, []);

  const fetchBusinessFee = async () => {
    let feeResponse = (await fetchBusinessCheckoutFee()).data.processingFee;
    setBusinessFee(feeResponse);
  }

  const getFinalRefundAmount = (type, includeFee, amount, name) => {
    const { amountBreakup, method } = props.data
    let finalAmount
    if (type === 'full') {
      finalAmount = parseFloat(amountBreakup.total)

      if (props.data.refund && props.data.refund.isRefunded) {
        finalAmount = finalAmount - props.data.refund.totalAmount
      }
    }

    if (type === 'base') {
      if (name === 'refundType') {
        let basePaid = parseFloat(props.data.refund.baseAmount).toFixed(2)
        let remainingBaseToBePaid = parseFloat(amountBreakup.net - amountBreakup.tip - basePaid)
        let remainingBaseFeeToBePaid = amountBreakup.feePaidByCustomer ? calculateBusinessProcessingFee(businessFee, remainingBaseToBePaid, method) : amountBreakup.fee
        let baseToBePaidWithoutFee = remainingBaseToBePaid

        finalAmount = parseFloat(baseToBePaidWithoutFee).toFixed(2)

        if (includeFee) {
          finalAmount = baseToBePaidWithoutFee + remainingBaseFeeToBePaid
        } else {
          finalAmount = parseFloat(baseToBePaidWithoutFee).toFixed(2)
        }
      } else {
        let partialAmount = parseFloat(amount)
        const amountFee = calculateBusinessProcessingFee(businessFee, partialAmount, method)
        let partialAmountWithFee = amountFee + partialAmount

        finalAmount = partialAmount

        if (includeFee) {
          finalAmount = partialAmountWithFee
        } else {
          finalAmount = partialAmount
        }
      }
    }
    
    if (type === 'tip') {
      if (name === 'refundType') {
        let tipAmount = amountBreakup.tip
        let tipPaid = parseFloat(props.data.refund.tipAmount).toFixed(2)

        let remainingTipToBePaid = parseFloat(tipAmount - tipPaid)
        let remainingTipFeeToBePaid = amountBreakup.feePaidByCustomer ? calculateBusinessProcessingFee(businessFee, remainingTipToBePaid, method) : 0
        let tipToBePaidWithoutFee = remainingTipToBePaid

        finalAmount = tipToBePaidWithoutFee
        if (includeFee) {
          finalAmount = tipToBePaidWithoutFee + remainingTipFeeToBePaid
        } else {
          finalAmount = tipToBePaidWithoutFee
        }
      } else {
        let partialAmount = parseFloat(amount)
        const amountFee = calculateBusinessProcessingFee(businessFee, partialAmount, method)
        let partialAmountWithFee = amountFee + partialAmount

        finalAmount = partialAmount

        if (includeFee) {
          finalAmount = partialAmountWithFee
        } else {
          finalAmount = partialAmount
        }
      }
    }

    if (name === 'refundType') {
      setAmount(parseFloat(finalAmount).toFixed(2))
    }
    setIncludeProcessingFee(includeFee)
    setRefundAmount(parseFloat(finalAmount).toFixed(2))
  }

  const handleChange = (name, value) => {
    if (name === 'amount') {
      getFinalRefundAmount(refundType, includeProcessingFee, value, name)
      if (!value) {
        setRefundAmount(parseFloat(0).toFixed(2))
      }
    } else if (name === 'refundType') {
      getFinalRefundAmount(value, value === 'full' ? true : false, amount, name)
    } else if (name === 'includeProcessingFee') {
      getFinalRefundAmount(refundType, value, amount, name)
    }
  }

  const handleSubmit = () => {
    if (!reason) {
      setReasonErr(true)
    }
    
    if (!amount) {
      setAmountErr(true)
    }
    
    if (!amountErr && !reasonErr) {
      const payload = {
        paymentId: props.data.id,
        amount: parseFloat(amount),
        includeProcessingFee: refundType === 'full' ? false : includeProcessingFee,
        refundAmount: parseFloat(refundAmount),
        type: refundType,
        reason,
        notes,
      }
      props.postRefund(payload, props.data.index)
    }
  }

  return (
    <Fragment>
      <Modal isOpen={props.open} toggle={props.handleRefundModalClose} centered>
        <ModalHeader
          toggle={props.handleRefundModalClose}
          className="py-modal__header__title"
        >
          <span>Refund to {props.data.customer}</span>
        </ModalHeader>
        <ModalBody className="Refund-Modal__body">
          <div className="py-form-field--condensed">
            <div className="py-form-field">
              <div className="py-form-field py-form-field--inline align-items-center">
                <div className="py-form-field__label">
                  Refund Type
                </div>
                <div className="py-form-field__element d-flex flex-column">
                  <label htmlFor="refundFull" className="py-radio">
                    <input
                      type="radio"
                      name="refundType"
                      value="full"
                      defaultChecked
                      id="refundFull"
                      onChange={() => {
                        handleChange('refundType', 'full')
                        setRefundType('full')
                      }}
                    />
                    <span className="py-form__element__faux"></span>
                    <span className="py-form__element__label">Refund full amount</span>
                  </label>
                  <label htmlFor="refundBase" className="py-radio">
                    <input
                      type="radio"
                      name="refundType"
                      value="base"
                      id="refundBase"
                      onChange={() => {
                        handleChange('refundType', 'base')
                        setRefundType('base')
                      }}
                    />
                    <span className="py-form__element__faux"></span>
                    <span className="py-form__element__label">Refund base amount</span>
                  </label>
                  {props.data && props.data.amountBreakup && props.data.amountBreakup.tip > 0 &&
                    <label htmlFor="refundTip" className="py-radio">
                      <input
                        type="radio"
                        name="refundType"
                        value="tip"
                        id="refundTip"
                        onChange={() => {
                          handleChange('refundType', 'tip')
                          setRefundType('tip')
                        }}
                      />
                      <span className="py-form__element__faux"></span>
                      <span className="py-form__element__label">Refund tip amount</span>
                    </label>
                  }
                </div>
              </div>
              {props.data.amountBreakup.feePaidByCustomer &&
                <div className="py-form-field">
                  <div className="py-form-field__element d-flex justify-content-center me-3">
                    <label htmlFor="includeProcessingFee" className="py-switch mb-1 mt-1">
                      <span className="py-toggle__label me-3">Include Processing Fee</span>
                      <input 
                        type="checkbox"
                        id="includeProcessingFee"
                        name="includeProcessingFee"
                        autoComplete="nope"
                        className="py-toggle__checkbox"
                        checked={refundType === 'full' || includeProcessingFee}
                        disabled={refundType === 'full'}
                        onChange={(e) => {
                          handleChange('includeProcessingFee', e.target.checked)
                          setIncludeProcessingFee(e.target.checked)
                        }}
                      />
                      <span className="py-toggle__handle"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="py-form-field py-form-field--inline align-items-center">
                <div className="py-form-field__label">Refund amount</div>
                <div className="py-form-field__element">
                  <InputGroup className="d-inline-flex flex-wrap">
                    <div className="refund-modal__amount">
                      <FormGroup className="py-form-field py-form-field--inline box-symble-field">
                        <div>
                          <InputGroup>
                            <InputGroupText
                              className={`prependAddon-input-card`}
                            >
                              {props.data.currency.symbol}
                            </InputGroupText>
                            {'   '}
                            <Input
                              onChange={e => {
                                handleChange('amount', e.target.value)
                                setAmount(e.target.value)
                                e.target.value && setAmountErr(false)
                              }}
                              value={amount}
                              type="number"
                              name="amount"
                              step="any"
                              disabled={refundType === 'full'}
                              className={
                                props.editMountRefund
                                  ? 'invoiceDisabled'
                                  : ''
                              }
                            />
                          </InputGroup>
                          <FormValidationError
                            showError={amountErr}
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="py-form-field py-form-field--inline align-items-center">
              <div className="py-form-field__label">
                Final Amount to be refunded:
              </div>
              <div className="py-form-field__element">
                {props.data.currency.symbol}{refundAmount}
              </div>
            </div>
            <div className="py-form-field py-form-field--inline align-items-center">
              <div className="py-form-field__label">
                {props.data.paymentType}
              </div>
              <div className="py-form-field__element">
                <GetRedirectUrl row={props.data} className="py-text--link" />
              </div>
            </div>
            {props.data.method !== 'alipay' &&
              <div className="py-form-field py-form-field--inline">
                <div className="py-form-field__label">Customer paid by</div>
                <div className="py-form-field__element">
                  {props.data.method == 'bank' ?
                    <div className={` py-payment-card py-payment__bank text-white`}>
                      <div className="py-payment__bank-icon">
                        <i className="fal fa-university"></i>
                      </div>
                      {props.data.bank && <div>
                        <div className="py-payment__bank-name">{props.data.bank.name}</div>
                        {props.data.bank.number && <div className="py-payment__bank-number">ending in {props.data.bank.number}</div>}
                      </div>}

                    </div>
                    : <Cards
                      number={
                        props.data.method !== 'bank'
                          ? props.data.card && props.data.card.number
                          : props.data.bank && props.data.bank.number
                      }
                      name={
                        props.data.method !== 'bank'
                          ? props.data.card.cardHolderName
                          : props.data.bank.name
                      }
                      issuer={props.data.paymentIcon}
                      preview={true}
                      method={props.data.method}
                    />}
                </div>
              </div>
            }
            <div className="py-form-field py-form-field--inline">
              <div className="py-form-field__label is-required">Reason</div>
              <div className="py-form-field__element">
                <SelectBox
                  style={{ maxWidth: '320px' }}
                  getOptionLabel={(value)=>(value["label"])}
                  getOptionValue={(value)=>(value["value"])}
                  isOptionSelected={(value) => {
                    return value["value"] === reason
                  }}
                  placeholder="Please select a reason"
                  id={'refund_reason'}
                  aria-required={true}
                  name="reason"
                  onChange={(e) => {
                    setReason(e.value);
                    setReasonErr(false);
                    handleChange('reason', e.value)
                  }
                  }
                  options={refundReasons}
                />
                <FormValidationError
                  showError={reasonErr}
                />
              </div>
            </div>
            <div className="py-form-field py-form-field--inline">
              <div className="py-form-field__label">Notes to self</div>
              <div className="py-form-field__element">
                <FormControl
                  id="refund_notes"
                  as="textarea"
                  rows="4"
                  value={notes}
                  aria-label="SSN"
                  className="py-form__element__medium"
                  aria-describedby="basic-addon1"
                  onChange={e => {
                    handleChange('notes', e)
                    setNotes(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
        <GetRedirectUrl row={props.data} viewButton="viewButton" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >Refund {props.loading && (<Spinner size="sm" color="default" />)}</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}
