export const SEARCH_BANKS_LOADING = 'SEARCH_BANKS_LOADING'
export const SEARCH_BANKS_SUCCESS = 'SEARCH_BANKS_SUCCESS'
export const SEARCH_BANKS_ERROR = 'SEARCH_BANKS_ERROR'

export const GET_BANKS_LOADING = 'GET_BANKS_LOADING'
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS'
export const GET_BANKS_ERROR = 'GET_BANKS_ERROR'

export const GET_CONNECTED_BANKS_LOADING = 'GET_CONNECTED_BANKS_LOADING'
export const GET_CONNECTED_BANKS_SUCCESS = 'GET_CONNECTED_BANKS_SUCCESS'
export const GET_CONNECTED_BANKS_ERROR = 'GET_CONNECTED_BANKS_ERROR'

export const DELETED_BANKS_LOADING = 'DELETED_BANKS_LOADING'
export const DELETED_BANKS_SUCCESS = 'DELETED_BANKS_SUCCESS'
export const DELETED_BANKS_ERROR = 'DELETED_BANKS_ERROR'

export const UPDATE_BALANCE_LOADING = 'UPDATE_BALANCE_LOADING'
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS'
export const UPDATE_BALANCE_ERROR = 'UPDATE_BALANCE_ERROR'