import React from "react";
import InvoiceForm from ".";

class AddInvoice extends React.Component{
    render(){
        return(
            <InvoiceForm/>
        )
    }
}

export default AddInvoice;