import React, { useState } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Icon from '../../components/common/Icon';
import { Tooltip, Button } from 'reactstrap';
import BrowserTabTitle from '../../global/browserTabTitle'
import symbolsIcon from "../../assets/icons/product/symbols.svg";
const Index = ({ isActive, plan }) => {
  const [tooltipOpen, setTooltipOpen] = useState({})

  const handleToggle = (target) => {
    setTooltipOpen({
      [target]: !tooltipOpen[target]
    })
  };
  return (
    <div className={`price-box ${isActive ? 'active' : ''}`}>
      <BrowserTabTitle title="Subscription plan" />
      <div className="price-header">
        {plan.trialDays ? <span className="price-badge">{plan.trialDays} Days Trial</span> : ""}
        <h3 className="price-title" >{plan.title}</h3>
        <div className="price-amount">${plan.price.toLocaleString()} USD</div>
        <div className="price-priod">per company/month</div>
      </div>
      <div className="price-body">
        <ul className="price-features">
          {plan.features.length > 0 && plan.features.map((feature, i) => (
            <li key={feature.title}>
              <Icon className="Icon" xlinkHref={`${symbolsIcon}#check-solid`} />
              {ReactHtmlParser(feature.title)}
              {feature.info && <a href="#" id={`Tooltip-${plan._id}-${i}`} className="info-ico ps-1" ><i class="fal fa-info-circle"></i>
                <Tooltip placement="top" isOpen={tooltipOpen[`Tooltip-${plan._id}-${i}`]} target={`Tooltip-${plan._id}-${i}`}
                  toggle={() => handleToggle(`Tooltip-${plan._id}-${i}`)}>
                  {feature.info}
                </Tooltip>
              </a>}
            </li>
          ))}
        </ul>
      </div>
      {/*<div className="price-footer">
        <Button color="primary" block onClick={()=>updatePlan(plan._id)} > {plan.trialDays > 0 ? `Start ${plan.trialDays} days trial` : 'Modify Plan'}</Button>
      </div>*/}
    </div>
  );
}

export default Index;