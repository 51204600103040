import React, { Fragment } from "react"
import history from "../../../../../../../customHistory";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { _paymentMethodIcons } from "../../../../../../../utils/GlobalFunctions";
import { _getUser } from "../../../../../../../utils/authFunctions";
import { DeleteModal } from '../../../../../../../utils/PopupModal/DeleteModal';
import CustomerAction from "./CustomerAction";

export const nameRender = (cell, row, rowIndex, formatExtraData) => {
    if(!!row){
        return (
            <Fragment>
                <span>
                    <a className="py-table__cell-content">{row.customerName}</a>
                    <span className="py-text--hint">{row.firstName} {' '} {row.lastName} </span>
                </span>
            </Fragment>
        )
    }
};

export const emailRender = (cell, row, rowIndex, formatExtraData) => {
    if(!!row){
        return (
            <Fragment>
                <a className="el-text" >{row.email}</a>
            </Fragment>
        )
    }
};

export const phoneRender = (cell, row, rowIndex, formatExtraData) => {
    if(!!row){
        return (
            <Fragment>
                <a className="el-text" >{!!row.communication ? row.communication.phone : '--'}</a>
            </Fragment>
        )
    }
};

export const paymntRender = (cell, row, rowIndex, formatExtraData) => {
    if(!!row){
        return (
            <Fragment>
                {row.cards && row.cards.length > 0 && row.cards.slice(0, 3).map((method, i) => {
                    return (
                        <OverlayTrigger
                        key={i}
                        placement="top"
                        overlay={
                        <Tooltip id={`tooltip-card`}>
                            Ending in {method.cardNumber}
                                </Tooltip>
                        }
                    >
                        <img
                        src={process.env.REACT_APP_WEB_URL.includes('localhost') ? `${_paymentMethodIcons(method.brand)}` : _paymentMethodIcons(method.brand)}
                        style={{ width: '40px', paddingRight: '5px' }} />
                        </OverlayTrigger>
                    )
                    })}
            </Fragment>
        )
    }
};

export const actionRender = (cell, row, rowIndex, formatExtraData) => {
    if(!!row){
        return (
            <CustomerAction row={row} />
        )
    }
};