import React from 'react';
import { Link } from "react-router-dom";
import BrowserTabTitle from '../../../../global/browserTabTitle'
import { connect } from 'react-redux';

const Index = ({ businessInfo }) => {
 return (
  <div className="subscribe-upgrade-page">
   <BrowserTabTitle title="Upgrade Subscription" />
   <div className="content-wrapper__main__fixed" >
    <div className="upgrade-plan-content col-9">
     <h2 className="title" >Uh oh, we have a problem</h2>
     <div className="desc">
      <p>You are currently subscribed to the {businessInfo.subscription && businessInfo.subscription.title ? businessInfo.subscription.title : 'Starter'} plan. <br /> Unfortunately, this feature is not available in the Starter plan. <br /> Please upgrade your subscription to access this feature.</p>
      <p>Would you like to upgrade your plan?</p>
     </div>
     <Link to="/app/setting/subscription-plans" className="btn btn-primary" >Yes, let’s upgrade now</Link>
    </div>
   </div>
  </div>
 );
}

const mapPropsToState = ({ businessReducer }) => ({
 businessInfo: businessReducer.selectedBusiness,
});

export default connect(mapPropsToState, {})(Index)