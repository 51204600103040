import { START_USER_DATA_LOADING, USER_DATA } from '../constants/ActionTypes';

const initialState = {
  loading: false,
  user: {}
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_USER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}

export default userReducer